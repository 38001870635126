import { useIntl } from 'react-intl';
import Select from 'react-select';

export type Props = {
    title: string,
    globalFilterValue: { value: string | boolean, label: string }[] | { value: string | boolean, label: string },
    options: any[],
    isClearable?: boolean,
    onChange: (selectedOptions: { value: string | boolean, label: string }) => void,
}

export default function SingleSelection({ title, globalFilterValue, options, isClearable = false, onChange }: Props) {
    const intl = useIntl();
    function handleChange(selectedOptions) {
        onChange(selectedOptions);
    };

    return (
        <div className='me-7'>
            {/* Header */}
            <div className='menu-content'>
                <div className='menu-content py-1'>
                    <span className='menu-section text-dark text-uppercase fs-8 ls-1'>
                        {title}
                    </span>
                </div>
            </div>
            {/* Content */}
            <div className='menu-content py-0 min-w-200px'>
                <Select
                    placeholder={intl.formatMessage({ id: "COMMON.SELECT" })}
                    className='border border-gray-100'
                    options={options}
                    value={globalFilterValue ?? []}
                    onChange={handleChange}
                    isClearable={isClearable}
                />
            </div>
        </div>
    )
}